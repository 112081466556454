import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormComponent } from './form/form.component';
import { ErrorComponent } from './error/error.component';

const routes: Routes = [
  { path: '', component: FormComponent },
  { path: 'es', component: FormComponent },
  { path: 'en', component: FormComponent },
  { path: 'fr', component: FormComponent },
  { path: 'de', component: FormComponent },
  { path: '**', component: ErrorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
