import { DataServices } from '../data.services';
import { Injectable, EventEmitter } from '@angular/core';
import { FormModel } from './form.model';

@Injectable()

export class FormularioService {

    respuesta = new EventEmitter<boolean>();

    constructor(private dataServices: DataServices) {
        this.dataServices.respuesta.subscribe(
            (respuestaService: boolean) => this.respuesta.emit(respuestaService)
        )
     }

    getLocation(url: string) {
        return this.dataServices.getLocationService(url);
    }

    insertarFormulario(formModel: FormModel): void {
        this.dataServices.insertarFormularioService(formModel);
    }
}