import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { FormModel } from './form/form.model';
import { FormularioService } from './form/form.service';

@Injectable()

export class DataServices {

    respuesta = new EventEmitter<boolean>();
    constructor(private httpClient: HttpClient) { }

    getLocationService(url: string) {
        return this.httpClient.get(url);
    }

    insertarFormularioService(formModel: FormModel): void {
        this.httpClient.post('https://serviciosclean.info/api/BolsaTrabajoRh/Insertar', formModel).subscribe(
            response => {
                this.respuesta.emit(true);
            },
            error => {
                this.respuesta.emit(false);
            }
        );
    }
}