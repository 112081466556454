export class FormTraducciones {
    public tituloForm: string;
    public subtituloForm: string;
    public tituloDatosPersonales: string;
    public tituloDatosContacto: string;
    public tituloDatosFormacion: string;
    public tituloDatosTecnicos: string;
    public subtituloDatosTecnicos: string;
    public tituloReferenciasLaborales: string;
    public nombre: string;
    public apellido: string;
    public pais: string;
    public nacionalidad: string;
    public provincia: string;
    public ciudad: string;
    public codigoPostal: string;
    public direccion: string;
    public edad: string;
    public coche: string;
    public sabeConducir: string;
    public puestoDeseado: string;
    public expectativaSalarial: string;
    public situacionFamiliar: string;
    public numeroHijos: string;
    public correoElectronico: string;
    public correoElectronicoAlt: string;
    public skype: string;
    public telefonoMovil: string;
    public telefonoCasa: string;
    public telefonoOficina: string;
    public nivelAcademico: string;
    public tituloPosee: string;
    public nivelIngles: string;
    public otroIdioma: string;
    public conocimientoHojaCalculo: string;
    public conocimientoProcesadoresTexto: string;
    public conocimientoGoogle: string;
    public tipoInternet: string;
    public velocidadInternet: string;
    public tipoComputadora: string;
    public descripcionComputadora: string;
    public poseeMouse: string;
    public poseeTeclado: string;
    public companiaInternet: string;
    public descripcionLugarTrabajo: string;
    public estabilidadDomiciliaria: string;
    public experenciaLaboral1: string;
    public jefeInmediato1: string;
    public aniosDuracion1: string;
    public telefono1: string;
    public experenciaLaboral2: string;
    public jefeInmediato2: string;
    public aniosDuracion2: string;
    public telefono2: string;
    public botonEnviar: string;
    public botonIdioma: string;
    public idiomas: Map<string, string> = new Map();
    public opcionSi: string;
    public opcionNo: string;
    public requerido: string;
    public correoValido: string;
    public correoInvalido: string;
    public mensajeOpciones: string;
    public enviandoFormulario: string;
    public formularioEnviado: string;
    public formularioNoEnviado: string;
    public gracias: string;
    public aviso: string;
    public nivelPermitido: string;
    public numeroPermitido: string;
    public pregunta: string;

    public getSpanish(): FormTraducciones {
        let traduccion = new FormTraducciones();
        traduccion.tituloForm = "BOLSA DE TRABAJO";
        traduccion.subtituloForm = "FORMULARIO DE CONTACTO";

        traduccion.tituloDatosPersonales = "Datos Personales";   
        traduccion.nombre = "Nombre: *";
        traduccion.apellido = "Apellido:";
        traduccion.pais = "País: *";
        traduccion.nacionalidad = "Nacionalidad: *";
        traduccion.provincia = "Provincia:";
        traduccion.ciudad = "Ciudad:";
        traduccion.codigoPostal = "Código Postal:";
        traduccion.direccion = "Dirección:";
        traduccion.edad = "Edad: *";
        traduccion.coche = "Coche:";
        traduccion.sabeConducir = "Sabe conducir:";
        traduccion.puestoDeseado = "Puesto en el que desea trabajar:";
        traduccion.expectativaSalarial = "Expectativa salarial:";
        traduccion.situacionFamiliar = "Situación familiar:";
        traduccion.numeroHijos = "Número de hijos:";
        traduccion.correoElectronico = "Correo eletrónico: *";
        traduccion.correoElectronicoAlt = "Correo electrónico alternativo:";

        traduccion.tituloDatosContacto = "Datos de Contacto";
        traduccion.skype = "Skype:";
        traduccion.telefonoMovil = "Teléfono móvil (cod. pais + número):";
        traduccion.telefonoCasa = "Teléfono de casa (cod. país + número):";
        traduccion.telefonoOficina = "Teléfono de oficina (cod. país + número):";

        traduccion.tituloDatosFormacion = "Datos de Formación";
        traduccion.nivelAcademico = "Nivel académico que posee:";
        traduccion.tituloPosee = "Título universitario o título que posee:";
        traduccion.nivelIngles = "Indique de 0 a 100 nivel de inglés que posee:";
        traduccion.otroIdioma = "Indique de 0 a 100 otro idioma que domine:";
        traduccion.conocimientoHojaCalculo = "Indique de 0 a 100 conocimiento de hoja de cálculo (Microsoft Excel, Google):";
        traduccion.conocimientoProcesadoresTexto = "Indique de 0 a 100 conocimiento de procesadores de texto (Microsoft Word):";
        traduccion.conocimientoGoogle = "Indique de 0 a 100 conocimiento de herramientas de Google(Drive, Maps, Gmail, Busqueda):";

        traduccion.tituloDatosTecnicos = "Datos Técnicos";
        traduccion.subtituloDatosTecnicos = "SOLO PARA CALLCENTER DESDE CASA LATINOAMERICA";
        traduccion.tipoInternet = "Tipo de internet que posee (Residencial, Móvil):";
        traduccion.velocidadInternet = "Velocidad de internet:";
        traduccion.tipoComputadora = "Tipo de computadora (de escritotio, laptop 15.6', minilaptop):";
        traduccion.descripcionComputadora = "Breve descripción de los componentes de su computadora:";
        traduccion.poseeMouse = "Posee mouse físico:";
        traduccion.poseeTeclado = "Posee teclado extendido (es el que tiene el panel numérico a la derecha):";
        traduccion.companiaInternet = "Compañía que le proporciona el internet:";
        traduccion.descripcionLugarTrabajo = "Descripción del lugar donde trabajará:";
        traduccion.estabilidadDomiciliaria = "Estabilidad domiciliaria:";

        traduccion.tituloReferenciasLaborales = "Referencias Laborales";
        traduccion.experenciaLaboral1 = "Experiencia laboral 1:";
        traduccion.aniosDuracion1 = "Años de duración:";
        traduccion.jefeInmediato1 = "Jefe inmediato:";
        traduccion.telefono1 = "Teléfono:";
        traduccion.experenciaLaboral2 = "Experiencia laboral 2:";
        traduccion.aniosDuracion2 = "Años de duración:";
        traduccion.jefeInmediato2 = "Jefe inmediato:";
        traduccion.telefono2 = "Teléfono:";

        traduccion.botonEnviar = "Enviar";
        traduccion.botonIdioma = "Sprache/Language/Langage";
        traduccion.idiomas.set("/en", "English");
        traduccion.idiomas.set("/fr", "French");
        traduccion.idiomas.set("/de", "Deutsch");
        traduccion.opcionSi = "SI";
        traduccion.opcionNo = "NO";
        traduccion.requerido = "Requerido!";
        traduccion.correoValido = "Correo válido";
        traduccion.correoInvalido = "Correo inválido";
        traduccion.mensajeOpciones = "Atención a las opciones de color rojo";
        traduccion.enviandoFormulario = "Enviando formulario...";
        traduccion.formularioEnviado = "Formulario enviado con éxito.";
        traduccion.formularioNoEnviado = "No se pudo enviar el formulario, por favor intente nuevamente.";
        traduccion.gracias = "Gracias!";
        traduccion.aviso = "Aviso";
        traduccion.nivelPermitido = "Número mínimo: 0, Número máximo: 100";
        traduccion.numeroPermitido = "Número mínimo: 0";
        traduccion.pregunta = "Está seguro de enviar el formulario?";

        return traduccion;
    }

    public getEnglish(): FormTraducciones {
        let traduccion = new FormTraducciones();
        traduccion.tituloForm = "JOB VACANCIES";
        traduccion.subtituloForm = "CONTACT FORM";

        traduccion.tituloDatosPersonales = "Personal Information";   
        traduccion.nombre = "First name: *";
        traduccion.apellido = "Last name:";
        traduccion.pais = "Country: *";
        traduccion.nacionalidad = "Nationality: *";
        traduccion.provincia = "Province:";
        traduccion.ciudad = "City:";
        traduccion.codigoPostal = "Postal Code:";
        traduccion.direccion = "Address:";
        traduccion.edad = "Age: *";
        traduccion.coche = "Car:";
        traduccion.sabeConducir = "Know how to drive:";
        traduccion.puestoDeseado = "Desired job position:";
        traduccion.expectativaSalarial = "Salary expectation:";
        traduccion.situacionFamiliar = "Family situation:";
        traduccion.numeroHijos = "Number of children:";
        traduccion.correoElectronico = "Email: *";
        traduccion.correoElectronicoAlt = "Alternate email:";

        traduccion.tituloDatosContacto = "Contact Information";
        traduccion.skype = "Skype:";
        traduccion.telefonoMovil = "Mobile phone (country code + complete number):";
        traduccion.telefonoCasa = "Home phone (country code + complete number):";
        traduccion.telefonoOficina = "Office phone (country code + complete number):";

        traduccion.tituloDatosFormacion = "Formation Data";
        traduccion.nivelAcademico = "Academic level:";
        traduccion.tituloPosee = "University degree or degree you hold:";
        traduccion.nivelIngles = "Indicate from 0 to 100 level of English that you have:";
        traduccion.otroIdioma = "Indicate from 0 to 100 another language that dominates:";
        traduccion.conocimientoHojaCalculo = "Indicate from 0 to 100 spreadsheet knowledge (Microsoft Excel, Google):";
        traduccion.conocimientoProcesadoresTexto = "Indicate from 0 to 100 knowledge of word processors (Microsoft Word):";
        traduccion.conocimientoGoogle = "Indicate 0 to 100 knowledge of Google tools (Drive, Maps, Gmail, Search):";

        traduccion.tituloDatosTecnicos = "Technical Data";
        traduccion.subtituloDatosTecnicos = "ONLY FOR CALLCENTER WORK FROM HOME LATINAMERICA";
        traduccion.tipoInternet = "Type of Internet you have (Residential, Mobile):";
        traduccion.velocidadInternet = "Internet speed:";
        traduccion.tipoComputadora = "Computer type (desktop PC, 15.6' laptop, minilaptop):";
        traduccion.descripcionComputadora = "Brief description of your computer components:";
        traduccion.poseeMouse = "Do you have a physical mouse:";
        traduccion.poseeTeclado = "Do you have an extended keyboard (it's the numeric panel on the right):";
        traduccion.companiaInternet = "Company that's provides you the internet:";
        traduccion.descripcionLugarTrabajo = "Description of the place where you will work:";
        traduccion.estabilidadDomiciliaria = "Household stability:";

        traduccion.tituloReferenciasLaborales = "Labor References";
        traduccion.experenciaLaboral1 = "Work experience 1:";
        traduccion.aniosDuracion1 = "Number of years:";
        traduccion.jefeInmediato1 = "Immediate boss:";
        traduccion.telefono1 = "Phone number:";
        traduccion.experenciaLaboral2 = "Work experience 2:";
        traduccion.aniosDuracion2 = "Number of years:";
        traduccion.jefeInmediato2 = "Immediate boss:";
        traduccion.telefono2 = "Phone number:";

        traduccion.botonEnviar = "Send";
        traduccion.botonIdioma = "Sprache/Idioma/Langage";
        traduccion.idiomas.set("/es", "Español");
        traduccion.idiomas.set("/fr", "French");
        traduccion.idiomas.set("/de", "Deutsch");
        traduccion.opcionSi = "YES";
        traduccion.opcionNo = "NO";
        traduccion.requerido = "Required!";
        traduccion.correoValido = "Valid email";
        traduccion.correoInvalido = "Invalid email";
        traduccion.mensajeOpciones = "Attention to red options";
        traduccion.enviandoFormulario = "Sending form ...";
        traduccion.formularioEnviado = "Form sent successfully";
        traduccion.formularioNoEnviado = "The form could not be sent, please try again.";
        traduccion.gracias = "Thank you!";
        traduccion.aviso = "Notice";
        traduccion.nivelPermitido = "Minimum number: 0, Maximum number: 100";
        traduccion.numeroPermitido = "Minimum number: 0";
        traduccion.pregunta = "Are you sure to send the form?";

        return traduccion;
    }

    public getDeutsch(): FormTraducciones {
        let traduccion = new FormTraducciones();
        traduccion.tituloForm = "STELLENANGEBOTE";
        traduccion.subtituloForm = "KONTAKT FORMULAR";

        traduccion.tituloDatosPersonales = "Persönliche Angaben";   
        traduccion.nombre = "Vorname: *";
        traduccion.apellido = "Nachname:";
        traduccion.pais = "Land: *";
        traduccion.nacionalidad = "Staatsangehörigkeit: *";
        traduccion.provincia = "Provinz:";
        traduccion.ciudad = "Stadt:";
        traduccion.codigoPostal = "Postleitzahl:";
        traduccion.direccion = "Adresse:";
        traduccion.edad = "Alter: *";
        traduccion.coche = "Auto:";
        traduccion.sabeConducir = "Wissen, wie man fährt:";
        traduccion.puestoDeseado = "Gewünschte Arbeitsstelle:";
        traduccion.expectativaSalarial = "Gehaltsvorstellung:";
        traduccion.situacionFamiliar = "Familiensituation:";
        traduccion.numeroHijos = "Anzahl der Kinder:";
        traduccion.correoElectronico = "Email: *";
        traduccion.correoElectronicoAlt = "Alternative email:";

        traduccion.tituloDatosContacto = "Kontaktinformation";
        traduccion.skype = "Skype:";
        traduccion.telefonoMovil = "Mobiltelefon (Landesvorwahl + vollständige Nummer):";
        traduccion.telefonoCasa = "Festnetztelefon (Landesvorwahl + vollständige Nummer):";
        traduccion.telefonoOficina = "Bürotelefon (Ländercode + vollständige Nummer):";

        traduccion.tituloDatosFormacion = "Bildungsdaten";
        traduccion.nivelAcademico = "Akademischer Grad:";
        traduccion.tituloPosee = "Universitätsabschluss oder Abschluss:";
        traduccion.nivelIngles = "Geben Sie zwischen 0 und 100 Englischkenntnisse an:";
        traduccion.otroIdioma = "Geben Sie von 0 bis 100 eine andere Sprache an, die dominiert:";
        traduccion.conocimientoHojaCalculo = "Geben Sie 0 bis 100 Tabellenkalkulationen an (Microsoft Excel, Google):";
        traduccion.conocimientoProcesadoresTexto = "Geben Sie 0 bis 100 Kenntnisse in Textverarbeitungsprogrammen an (Microsoft Word):";
        traduccion.conocimientoGoogle = "Geben Sie 0 bis 100 Kenntnisse über Google-Tools an (Drive, Maps, Gmail, Suche):";

        traduccion.tituloDatosTecnicos = "Technische Daten";
        traduccion.subtituloDatosTecnicos = "NUR FÜR CALLCENTER-ARBEITEN VON HOME LATINAMERICA";
        traduccion.tipoInternet = "Art des Internets, das Sie haben  (Wohn, Handy, Mobiltelefon):";
        traduccion.velocidadInternet = "Internet-Geschwindigkeit:";
        traduccion.tipoComputadora = "Computertyp (Desktop-PC, 15,6 Laptop, Minilaptop):";
        traduccion.descripcionComputadora = "Kurze Beschreibung Ihrer Computerkomponenten:";
        traduccion.poseeMouse = "Hast du eine physische Maus?:";
        traduccion.poseeTeclado = "Hast du eine erweiterte Tastatur (Es ist das numerische Feld auf der rechten Seite):";
        traduccion.companiaInternet = "Firma, die Ihnen das Internet zur Verfügung stellt:";
        traduccion.descripcionLugarTrabajo = "Beschreibung des Ortes, an dem Sie arbeiten werden:";
        traduccion.estabilidadDomiciliaria = "Haushaltsstabilität:";

        traduccion.tituloReferenciasLaborales = "Arbeitsreferenzen";
        traduccion.experenciaLaboral1 = "Berufserfahrung 1:";
        traduccion.aniosDuracion1 = "Anzahl von Jahren:";
        traduccion.jefeInmediato1 = "Sofortiger Chef:";
        traduccion.telefono1 = "Telefonnummer:";
        traduccion.experenciaLaboral2 = "Berufserfahrung 2:";
        traduccion.aniosDuracion2 = "Anzahl von Jahren:";
        traduccion.jefeInmediato2 = "Sofortiger Chef:";
        traduccion.telefono2 = "Telefonnummer:";

        traduccion.botonEnviar = "Senden";
        traduccion.botonIdioma = "Language/Idioma/Langage";
        traduccion.idiomas.set("/es", "Español");
        traduccion.idiomas.set("/fr", "French");
        traduccion.idiomas.set("/en", "English");
        traduccion.opcionSi = "JA";
        traduccion.opcionNo = "NEIN";
        traduccion.requerido = "Erforderlich!";
        traduccion.correoValido = "Gültige E-Mail";
        traduccion.correoInvalido = "Ungültige E-Mail";
        traduccion.mensajeOpciones = "Achtung rote Optionen";
        traduccion.enviandoFormulario = "Formular senden ...";
        traduccion.formularioEnviado = "Formular erfolgreich gesendet";
        traduccion.formularioNoEnviado = "Das Formular konnte nicht gesendet werden. Bitte versuchen Sie es erneut.";
        traduccion.gracias = "Danke!";
        traduccion.aviso = "Beachten";
        traduccion.nivelPermitido = "Mindestanzahl: 0, Höchstzahl: 100";
        traduccion.numeroPermitido = "Mindestanzahl: 0";
        traduccion.pregunta = "Sind Sie sicher, das Formular zu senden?";

        return traduccion;
    }

    public getFrench(): FormTraducciones {
        let traduccion = new FormTraducciones();
        traduccion.tituloForm = "Sac de travail";
        traduccion.subtituloForm = "FORMULAIRE DE CONTACT";

        traduccion.tituloDatosPersonales = "Informations Personnelles";   
        traduccion.nombre = "Nom: *";
        traduccion.apellido = "Le nom:";
        traduccion.pais = "Pays: *";
        traduccion.nacionalidad = "Nationalité: *";
        traduccion.provincia = "Province:";
        traduccion.ciudad = "Ville:";
        traduccion.codigoPostal = "Code postal:";
        traduccion.direccion = "Adresse:";
        traduccion.edad = "Âge: *";
        traduccion.coche = "Voiture:";
        traduccion.sabeConducir = "Savoir conduire:";
        traduccion.puestoDeseado = "Poste souhaité:";
        traduccion.expectativaSalarial = "Attentes salariales:";
        traduccion.situacionFamiliar = "Situation familiale:";
        traduccion.numeroHijos = "Nombre d'enfants:";
        traduccion.correoElectronico = "E-mail: *";
        traduccion.correoElectronicoAlt = "e-mail secondaire:";

        traduccion.tituloDatosContacto = "Informations de Contact";
        traduccion.skype = "Skype:";
        traduccion.telefonoMovil = "Téléphone portable (numéro de code de pays + numéro complet):";
        traduccion.telefonoCasa = "Téléphone fixe (numéro de code de pays + numéro complet):";
        traduccion.telefonoOficina = "Téléphone de bureau (numéro de code de pays + numéro complet):";

        traduccion.tituloDatosFormacion = "Données d'entraînement";
        traduccion.nivelAcademico = "Niveau académique:";
        traduccion.tituloPosee = "Diplôme universitaire ou grade que vous détenez:";
        traduccion.nivelIngles = "Indiquez de 0 à 100 niveaux d'anglais que vous avez:";
        traduccion.otroIdioma = "Indiquez de 0 à 100 une autre langue qui domine:";
        traduccion.conocimientoHojaCalculo = "Indiquez de 0 à 100 connaissances de la feuille de calcul (Microsoft Excel, Google):";
        traduccion.conocimientoProcesadoresTexto = "Indiquez de 0 à 100 connaissances des traitements de texte (Microsoft Word):";
        traduccion.conocimientoGoogle = "Indiquez une connaissance de 0 à 100 des outils Google. (Drive, Maps, Gmail, Chercher):";

        traduccion.tituloDatosTecnicos = "Données Techniques";
        traduccion.subtituloDatosTecnicos = "SEULEMENT POUR CALLCENTER TRAVAIL À DOMICILE AMÉRIQUE LATINE";
        traduccion.tipoInternet = "Type d'Internet que vous avez (Résidentiel, Mobile):";
        traduccion.velocidadInternet = "Vitesse d'Internet:";
        traduccion.tipoComputadora = "Type d'ordinateur (ordinateur de bureau, 15.6' portable, mini ordinateur portable):";
        traduccion.descripcionComputadora = "Brève description de vos composants informatiques:";
        traduccion.poseeMouse = "Avez-vous une souris physique:";
        traduccion.poseeTeclado = "Avez-vous un clavier étendu (c'est le panneau numérique à droite):";
        traduccion.companiaInternet = "Société qui vous fournit l'internet:";
        traduccion.descripcionLugarTrabajo = "Description du lieu où vous travaillerez:";
        traduccion.estabilidadDomiciliaria = "La stabilité des ménages:";

        traduccion.tituloReferenciasLaborales = "Références de travail";
        traduccion.experenciaLaboral1 = "Expérience de travail 1:";
        traduccion.aniosDuracion1 = "Nombre d'années:";
        traduccion.jefeInmediato1 = "Patron immédiat:";
        traduccion.telefono1 = "Numéro de téléphone:";
        traduccion.experenciaLaboral2 = "Expérience de travail 2:";
        traduccion.aniosDuracion2 = "Nombre d'années:";
        traduccion.jefeInmediato2 = "Patron immédiat:";
        traduccion.telefono2 = "Numéro de téléphone:";

        traduccion.botonEnviar = "Envoyer";
        traduccion.botonIdioma = "Sprache/Language/Idioma";
        traduccion.idiomas.set("/es", "Español");
        traduccion.idiomas.set("/de", "Deutsch");
        traduccion.idiomas.set("/en", "English");
        traduccion.opcionSi = "OUI";
        traduccion.opcionNo = "NON";
        traduccion.requerido = "Requis!";
        traduccion.correoValido = "Email valide";
        traduccion.correoInvalido = "Email invalide";
        traduccion.mensajeOpciones = "Attention aux options rouges";
        traduccion.enviandoFormulario = "Envoi du formulaire ...";
        traduccion.formularioEnviado = "Formulaire envoyé avec succès";
        traduccion.formularioNoEnviado = "Le formulaire n'a pas pu être envoyé. Veuillez réessayer.";
        traduccion.gracias = "Merci!";
        traduccion.aviso = "Remarquer";
        traduccion.nivelPermitido = "Nombre minimum: 0, Nombre maximum: 100";
        traduccion.numeroPermitido = "Nombre minimum: 0";
        traduccion.pregunta = "Etes-vous sûr d'envoyer le formulaire?";

        return traduccion;
    }
}