import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormTraducciones } from './form.traducciones';
import { FormModel } from './form.model';
import { FormularioService } from './form.service';
import { Title } from '@angular/platform-browser';

import Swal from 'sweetalert2'; //import para sweetalert

declare var $: any;

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {
  traduccion: FormTraducciones;
  modelo: FormModel;
  validador: number = 0;
  paisPeticion: string = "";


  constructor(private router: Router, private formularioService: FormularioService, private titleService: Title) {
    this.formularioService.respuesta.subscribe(
      (respuestaForm: boolean) => {
        if (respuestaForm) {
          Swal.fire({
            title: this.traduccion.aviso,
            text: this.traduccion.formularioEnviado,
            type: 'success',
            confirmButtonText: this.traduccion.gracias,
            allowEscapeKey: false,
            allowOutsideClick: false
          }).then((result) => {
            if (result.value) {
              window.location.reload();
            }
          });
        } else {
          Swal.fire({
            title: this.traduccion.aviso,
            text: this.traduccion.formularioNoEnviado,
            type: 'error',
            confirmButtonText: this.traduccion.gracias,
            allowEscapeKey: false,
            allowOutsideClick: false
          });
        }
      }
    )
  }

  ngOnInit() {
    if (this.router.url == "/" || this.router.url == "/es") {
      this.traduccion = new FormTraducciones().getSpanish();
      this.titleService.setTitle(this.traduccion.subtituloForm);
    }
    if (this.router.url == "/en") {
      this.traduccion = new FormTraducciones().getEnglish();
      this.titleService.setTitle(this.traduccion.subtituloForm);
    }
    if (this.router.url == "/de") {
      this.traduccion = new FormTraducciones().getDeutsch();
      this.titleService.setTitle(this.traduccion.subtituloForm);
    }
    if (this.router.url == "/fr") {
      this.traduccion = new FormTraducciones().getFrench();
      this.titleService.setTitle(this.traduccion.subtituloForm);
    }
    this.modelo = new FormModel();
    this.getPais();
  }

  getPais() {
    //15000 peticiones por hora completamente gratis
    this.formularioService.getLocation("https://freegeoip.app/json/").subscribe(
      (json: any) => {
        if (json.country_name) {
          this.paisPeticion = json.country_name;
        } else {
          //completamente gratis no detalla peticiones
          this.formularioService.getLocation("https://api.ip.sb/geoip").subscribe(
            (json: any) => {
              if (json.country) {
                this.paisPeticion = json.country;
              } else {
                //50 peticiones por minuto
                this.formularioService.getLocation("//extreme-ip-lookup.com/json/").subscribe(
                  (json: any) => {
                    if (json.country) {
                      this.paisPeticion = json.country;
                    } else {
                      //1000 peticiones al dia
                      this.formularioService.getLocation("https://ipapi.co/json/").subscribe(
                        (json: any) => {
                          if (json.country_name) {
                            this.paisPeticion = json.country_name;
                          }
                        }
                      );
                    }
                  }
                );
              }
            }
          );
        }
      }
    );
  }

  enviarFormulario() {
    Swal.fire({
      type: 'info',
      title: this.traduccion.aviso,
      text: this.traduccion.enviandoFormulario,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false
    });
    this.modelo.nota2 = "INGRESADO DESDE: RH21.NET";
    this.modelo.nota2 += this.router.url == "/" ? "/es" : this.router.url;
    this.modelo.cpais += " / " + this.paisPeticion;
    this.formularioService.insertarFormulario(this.modelo);
  }

  onEnviar() {
    if (this.isValid()) {
      //codigo alert
      Swal.fire({
        title: this.traduccion.aviso,
        text: this.traduccion.pregunta,
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.traduccion.opcionSi,
        cancelButtonText: this.traduccion.opcionNo,
        reverseButtons: true,
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then((result) => {
        if (result.value) {
          this.enviarFormulario();
        }
      })
    }
  }
  validarEmail(correo: string) {
    let emailRegex = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
    return emailRegex.test(correo);
  }

  isValid(): boolean {
    this.validador = 1;
    if (
      this.modelo.nombre == "" ||
      this.modelo.cpais == "" ||
      this.modelo.nacionalidad == "" ||
      this.modelo.edad <= 0 ||
      this.modelo.edad == null ||
      this.modelo.movil == "" ||
      this.modelo.movil == null ||
      this.modelo.email == "" ||
      this.modelo.exp_salarial < 0 ||
      this.modelo.exp_salarial == null ||
      this.modelo.num_hijos < 0 ||
      this.modelo.num_hijos == null ||
      this.modelo.nivel_ingles < 0 ||
      this.modelo.nivel_ingles > 100 ||
      this.modelo.nivel_ingles == null ||
      this.modelo.nivel_idioma < 0 ||
      this.modelo.nivel_idioma > 100 ||
      this.modelo.nivel_idioma == null ||
      this.modelo.nivel_excel < 0 ||
      this.modelo.nivel_excel > 100 ||
      this.modelo.nivel_excel == null ||
      this.modelo.nivel_word < 0 ||
      this.modelo.nivel_word > 100 ||
      this.modelo.nivel_word == null ||
      this.modelo.nivel_google < 0 ||
      this.modelo.nivel_google > 100 ||
      this.modelo.nivel_google == null ||
      this.modelo.duracion1 < 0 ||
      this.modelo.duracion1 == null ||
      this.modelo.duracion2 < 0 ||
      this.modelo.duracion2 == null
    ) {
      return false;
    }
    if (!this.validarEmail(this.modelo.email))
      return false;
    if (this.modelo.email_alt != "" && !this.validarEmail(this.modelo.email_alt))
      return false;
    return true;
  }
}
