export class FormModel{
    public nombre:string;
    public apellido:string;
    public cpais:string;
    public nacionalidad:string;
    public provincia:string;
    public ciudad:string;
    public Codpost:string;
    public direccion:string;
    public edad:number;
    public coche:boolean;
    public sabe_conducir:boolean;
    public puestod:string;
    public exp_salarial:number;
    public situacion_f:string;
    public num_hijos:number;
    public email:string;
    public email_alt:string;
    public user_skype:string;
    public movil:string;
    public telefono:string;
    public telefono_of:string;
    public nivel_academico:string;
    public titulo_u:string;
    public nivel_ingles:number;
    public nivel_idioma:number;
    public nivel_excel:number;
    public nivel_word:number;
    public nivel_google:number;
    public tipo_internet:string;
    public velocidad_internet:string;
    public tipo_pc:string;
    public descripcion_pc:string;
    public mouse_f:boolean;
    public teclado_ext:boolean;
    public comp_internet:string;
    public lugar_trabaja:string;
    public estabilidad_dom:string;
    public trabajo1:string;
    public jefe_inmediato1:string;
    public duracion1:number;
    public telefono_r1:string;
    public trabajo2:string;
    public jefe_inmediato2:string;
    public duracion2:number;
    public telefono_r2:string;
    public nota2: string;

    constructor(){
        this.nombre = "";
        this.apellido = "";
        this.cpais = "";
        this.nacionalidad = "";
        this.provincia = "";
        this.ciudad = "";
        this.Codpost = "";
        this.direccion = "";
        this.edad = 0;
        this.coche = false;
        this.sabe_conducir = false;
        this.puestod = "";
        this.exp_salarial = 0;
        this.situacion_f = "";
        this.num_hijos = 0;
        this.email = "";
        this.email_alt = "";
        this.user_skype = "";
        this.movil = "";
        this.telefono = "";
        this.telefono_of = "";
        this.nivel_academico = "";
        this.titulo_u = "";
        this.nivel_ingles = 0;
        this.nivel_idioma = 0;
        this.nivel_excel = 0;
        this.nivel_word = 0;
        this.nivel_google = 0;
        this.tipo_internet = "";
        this.velocidad_internet = "";
        this.tipo_pc = "";
        this.descripcion_pc = "";
        this.mouse_f = false;
        this.teclado_ext = false;
        this.comp_internet = "";
        this.lugar_trabaja = "";
        this.estabilidad_dom = "";
        this.trabajo1 = "";
        this.duracion1 = 0;
        this.jefe_inmediato1 = "";
        this.telefono_r1 = "";
        this.trabajo2 = "";
        this.duracion2 = 0;
        this.jefe_inmediato2 = "";
        this.telefono_r2 = "";
        this.nota2 = "";
    }
}